<template>
  <v-btn
    :style="styles"
    color="deep-purple darken-4"
    dark
    fixed
    href="https://gomesunonahagomesu.bandcamp.com/"
    rel="noopener"
    right
    target="_blank"
    top
    class="hidden-md-and-down light-green--text text--accent-3"
  >
    Bandcamp
  </v-btn>
</template>

<script>
export default {
  name: "CoreCta",

  computed: {
    styles() {
      return {
        margin: `-3px 10px 0 0`,
        zIndex: `10`,
      };
    },
  },
};
</script>
